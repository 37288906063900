import { createLucideIcon } from '@qasa/qds-ui'
import { Coins, Sofa, Users2, ArrowUpRight, Video, TrainFront, TramFront, BusFront, Ship } from 'lucide-react'

export const CoinsIcon = createLucideIcon(Coins)

export const FurnitureIcon = createLucideIcon(Sofa)

export const UserGroupIcon = createLucideIcon(Users2)

export const ArrowUpRightIcon = createLucideIcon(ArrowUpRight)

export const VideoIcon = createLucideIcon(Video)
export const TrainFrontIcon = createLucideIcon(TrainFront)
export const TramFrontIcon = createLucideIcon(TramFront)
export const BusFrontIcon = createLucideIcon(BusFront)
export const ShipIcon = createLucideIcon(Ship)
