/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/naming-convention */
import type { FilteringStyledOptions, StyledOptions, CreateStyledComponent } from '@emotion/native'
import type { ViewStyle, TextStyle, ImageStyle } from 'react-native'

import type { Theme as CommonTheme } from '../theme'

import { styled as platformSpecificStyled } from './styled'

/**
 * Add necessary styling properties here. The properties must work the same for native and web.
 */
type StyleType =
  | Pick<
      ViewStyle,
      | 'backgroundColor'
      | 'display'
      | 'borderBottomWidth'
      | 'borderLeftWidth'
      | 'borderRightWidth'
      | 'borderTopWidth'
      | 'borderWidth'
      | 'borderColor'
      | 'borderRadius'
      | 'borderBottomRightRadius'
      | 'borderTopRightRadius'
      | 'height'
      | 'width'
      | 'maxHeight'
      | 'maxWidth'
      | 'minHeight'
      | 'minWidth'
      | 'overflow'
      | 'flex'
      | 'alignItems'
      | 'alignSelf'
      | 'justifyContent'
      | 'flexGrow'
      | 'flexShrink'
      | 'flexBasis'
      | 'flexWrap'
      | 'flexDirection'
      | 'gap'
      | 'position'
      | 'top'
      | 'bottom'
      | 'left'
      | 'right'
      | 'padding'
      | 'paddingLeft'
      | 'paddingRight'
      | 'paddingBottom'
      | 'paddingTop'
      | 'margin'
      | 'marginRight'
      | 'marginLeft'
      | 'marginTop'
      | 'marginBottom'
      | 'zIndex'
      | 'columnGap'
      | 'rowGap'
    >
  | Pick<TextStyle, 'textDecorationLine' | 'color' | 'fontSize' | 'fontFamily' | 'textAlign'>
  | Pick<ImageStyle, 'objectFit'>

/**
 * Type is copied from the emotion package as emotion does not allow multiple themes in the same package.
 * Theme is managed by the @emotion/react package for both @emotion/native and @emotion/styled
 */
type CreateStyled = {
  <
    C extends React.ComponentClass<React.ComponentProps<C>>,
    ForwardedProps extends keyof React.ComponentProps<C> & string = keyof React.ComponentProps<C> & string,
  >(
    component: C,
    options: FilteringStyledOptions<React.ComponentProps<C>, ForwardedProps>,
  ): CreateStyledComponent<
    Pick<React.ComponentProps<C>, ForwardedProps> & {
      theme?: CommonTheme
      as?: React.ElementType
    },
    {},
    { ref?: React.Ref<InstanceType<C>> },
    StyleType
  >

  <C extends React.ComponentClass<React.ComponentProps<C>>>(
    component: C,
    options?: StyledOptions<React.ComponentProps<C>>,
  ): CreateStyledComponent<
    React.ComponentProps<C> & {
      theme?: CommonTheme
      as?: React.ElementType
    },
    {},
    { ref?: React.Ref<InstanceType<C>> },
    StyleType
  >

  <
    C extends React.ComponentType<React.ComponentProps<C>>,
    ForwardedProps extends keyof React.ComponentProps<C> & string = keyof React.ComponentProps<C> & string,
  >(
    component: C,
    options: FilteringStyledOptions<React.ComponentProps<C>, ForwardedProps>,
  ): CreateStyledComponent<
    Pick<React.ComponentProps<C>, ForwardedProps> & {
      theme?: CommonTheme
      as?: React.ElementType
    },
    {},
    {},
    StyleType
  >

  <C extends React.ComponentType<React.ComponentProps<C>>>(
    component: C,
    options?: StyledOptions<React.ComponentProps<C>>,
  ): CreateStyledComponent<
    React.ComponentProps<C> & { theme?: CommonTheme; as?: React.ElementType },
    {},
    {},
    StyleType
  >
}

export const styled = platformSpecificStyled as CreateStyled
