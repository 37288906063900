import type { ComponentPropsWithoutRef, ElementType } from 'react'
import type { Theme } from '@qasa/qds-ui'

import { styled } from '../../web'

type ImageWrapperProps = {
  borderRadius: keyof Theme['radii']
  $width: string | number
  $height: string | number
}
const ImageWrapper = styled('div')<ImageWrapperProps>(({ theme, $width, $height, borderRadius }) => ({
  width: $width,
  height: $height,
  borderRadius: theme.radii[borderRadius],
  overflow: 'hidden',
  background: 'transparent',
  flexShrink: 0,
}))

const StyledImg = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
})

type ImageOptions = {
  src?: string
  alt?: string
  width: string | number
  height: string | number
  loading?: 'eager' | 'lazy'
  borderRadius?: keyof Theme['radii']
}

type OmittedProps = 'width' | 'height'

type HTMLQdsProps<T extends ElementType> = ComponentPropsWithoutRef<T>

export type ImageProps = Omit<HTMLQdsProps<'img'>, OmittedProps> & ImageOptions
export function Image({
  src,
  loading = 'lazy',
  width,
  height,
  borderRadius = 'md',
  ...restProps
}: ImageProps) {
  return (
    <ImageWrapper $width={width} $height={height} borderRadius={borderRadius}>
      <StyledImg src={src} loading={loading} {...restProps} />
    </ImageWrapper>
  )
}
